import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from "../features/inputGraphEditor/components/DashBoard";
import LoginPage from "../features/login/LoginPage";
import SignUpPage from "../features/login/SignUpPage";
import PaymentPage from "../features/payment/PaymentPage";
import InputGraphEditorPage from "../features/inputGraphEditor/InputGraphEditorPage";
import HomePage from "../features/home/HomePage";
import OutputEditorPage from "../features/outputEditor/OutputEditorPage";
import { ProtectedRoute } from "./ProtectedRoute";
import Toast from "../common-components/Toast"; // Make sure this path is correct
import { useEffect } from "react";
import { initGA } from "../utils/analytics";
import RouteTracker from "../common-components/RouterTracker";
import VerifyEmail from "../features/login/VerifyEmail";
import ForgotPassword from "../features/login/ForgotPassword";
import ResetPassword from "../features/login/ResetPassword";
import Plans from "../features/payment/Plans";

function Home() {
  return (
    <Router>
      <RouteTracker />
      <div>
        {/* Routes setup */}
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/forgotpass" element={<ForgotPassword />} />
          <Route
            path="reset-password/:user_id/:token"
            element={<ResetPassword />}
          />

          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/inputeditor" element={<InputGraphEditorPage />} />
            <Route path="/outputeditor" element={<OutputEditorPage />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/payment" element={<PaymentPage />} />
          </Route>

          {/* Catch All Route */}
          <Route path="*" element={<div>PATH DNE</div>} />
        </Routes>

        {/* Toast component */}
        <Toast />
      </div>
    </Router>
  );
}

export default Home;
