import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setSelectedWallOption } from '../../../redux/features/appState';

export default function SelectBar() {
    const selectedWall = useSelector(
        (state: RootState) => state.outputEditorButtonState.selectedWall,
      );
    const dispatch = useDispatch();

  return (
    <div>
        {selectedWall && (
        <div
          // ref={dropdownRef}
          style={{
            position: "absolute",
            left: (selectedWall.x1 + selectedWall.x2) / 2,
            top: (selectedWall.y1 + selectedWall.y2) / 2,
            zIndex: 1000,
            backgroundColor: "white",
            border: "2px solid #a3b7c1", // Light blue border color
            padding: "5px",
            borderRadius: "12px", // Rounded border to match the reference
            transform: "translate(-50%, -50%)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            display: "flex",
            alignItems: "center",
          }}
        >
          <select
            // value={hoveredEdge.color}
            onChange={(e) => dispatch(setSelectedWallOption(e.target.value))}
            style={{
              border: "none",
              outline: "none",
              color: "#204963", // Dark blue color for text
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: "#e3eff5", // Light blue background color
              fontWeight: "bold",
              appearance: "none", // Remove default arrow
              marginRight: "5px",
            }}
          >
            <option value="" disabled selected hidden>
    -- Select an option --
  </option>
            <option value="select">Select</option>
            <option value="unselect">Unselect</option>
            <option value="delete">Delete</option>
          </select>
          <span
            style={{
              display: "inline-block",
              borderTop: "5px solid #204963", // Dark blue arrow color
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              marginLeft: "-15px", // Adjust to place the arrow over dropdown
            }}
          ></span>
        </div>
      )}
    </div>
  )
}
