import React from "react";
import { useState } from "react";
import { Box, Typography, Paper, Button, MobileStepper, Tooltip } from "@mui/material";
import help from "./../../../assets/inputGraphEditor/help.svg";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import RightArrowIcon from "../../../common-components/icons/RightArrowIcon";
import SelectBoxIcon from "../../../common-components/icons/SelectBoxIcon";
import TagIcon from "../../../common-components/icons/TagIcon";
import TextBoxIcon from "../../../common-components/icons/TextBoxIcon";
import UpLeftCursorIcon from "../../../common-components/icons/UpLeftCursorIcon";
import PenIcon from "../../../common-components/icons/PenIcon";
import ColorPotIcon from "../../../common-components/icons/ColorPotIcon";
import RightCurvedArrowIcon from "../../../common-components/icons/RightCurvedArrowIcon";
import LeftCurvedArrowIcon from "../../../common-components/icons/LeftCurvedArrowIcon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useTheme } from '@mui/material/styles';
import {
  popRedoStacks,
  popUndoStacks,
  pushRedoStacks,
  pushUndoStacks,
  setCleanUpDone,
  setRooms,
  setWalls,
  setDrawLine,
  clearDrawLine,
  setPlot,
  togglePlot,
} from "../../../redux/features/appState";
import { getFloorplanLimits } from "../utils/helpers";
import { Wall } from "./Floorplan";
import { v4 as uuidv4 } from "uuid";
import DimensionsToggle from "../../../common-components/DimensionsToggle";


export default function BottomNav() {

  const steps = [
    {
      title: 'Move Walls',
      description: 'Click on any wall to select it. Drag selected walls to adjust their position. Wall movement is allowed without breaking adjacencies.',
    },
    {
      title: 'Delete Walls',
      description: 'Select a wall and double click on it to remove it.',
    },
    {
      title: 'Add Doors',
      description: 'Click on a wall and then click on assets to add a door. Move the door along the wall to adjust its position.',
    },
    {
      title: 'Add Plot',
      description: 'Use the second button on the utilities tab to add a plot for your floorplan. Configure the plot’s dimensions and position before placing it.',
    },
    {
      title: 'Switch Units',
      description: 'Toggle between Feet and Meters to adjust all dimensions automatically. Make sure to save any changes before switching.',
    },
    {
      title: 'Undo & Redo Changes',
      description: 'Use the Undo and Redo buttons at the bottom right to revert or reapply your last actions.',
    },
    {
      title: 'Use the Pen Tool',
      description: 'Click on the Pen Tool to manually add walls to the floorplan. Draw walls by clicking and dragging on the canvas. To exit the Pen Tool, click the close button near it.',
    },
    {
      title: 'Save Floorplan & Graph',
      description: 'Save your progress by clicking Save. The graph and floorplan data will be stored for later use.',
    },
  ];


  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const tooltipContent = (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'white',
        }}
      >
        {/* <Typography sx={{ mr: '2rem' }}>{steps[activeStep].icon}</Typography> */}
        <Typography>{steps[activeStep].title}</Typography>
      </Paper>
      <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2, fontSize: '.9rem' }}>
        {steps[activeStep].description}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );

  const walls = useSelector(
    (state: RootState) => state.outputEditorButtonState.walls,
  );
  const rooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.rooms,
  );
  const showPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.showPlot,
  );
  const plot_boundary = useSelector(
    (state: RootState) => state.outputEditorButtonState.plot_boundary,
  );
  const undoWalls = useSelector(
    (state: RootState) => state.outputEditorButtonState.undoWalls,
  );
  const undoRooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.undoRooms,
  );
  const undoPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.undoPlot,
  );
  const redoWalls = useSelector(
    (state: RootState) => state.outputEditorButtonState.redoWalls,
  );
  const redoRooms = useSelector(
    (state: RootState) => state.outputEditorButtonState.redoRooms,
  );
  const redoPlot = useSelector(
    (state: RootState) => state.outputEditorButtonState.redoPlot,
  );

  const unit = useSelector(
    (state: RootState) => state.outputEditorButtonState.unit,
  );
  const floorPlans = useSelector((state: RootState) => state.graph.floorPlans);
  const index = useSelector((state: RootState) => state.graph.index);
  let graphdata = useSelector((state: any) => state.graph.graphData);
  let plotWidth = graphdata.plot_width;
  let plotHeight = graphdata.plot_height;

  const dispatch = useDispatch<AppDispatch>();

  // Helper function to safely get viewport dimensions
  const getViewportDimension = (isWidth: boolean): number => {
    if (typeof window === "undefined" || !window.visualViewport) {
      return isWidth ? 1920 : 1080; // Fallback values
    }
    return isWidth ? window.visualViewport.width : window.visualViewport.height;
  };

  // Updated scaling function
  const scaleCoordinate = (
    coord: number,
    isX: boolean,
    width: number,
    height: number,
  ) => {
    return coord * ((getViewportDimension(false) * 0.5) / height);
  };

  const undo = () => {
    if (undoWalls.length > 0 && undoRooms.length > 0)
      dispatch(
        pushRedoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }),
      );
    if (undoWalls?.length > 0) {
      dispatch(setWalls(undoWalls[undoWalls.length - 1]));
    }
    if (undoRooms?.length > 0) {
      dispatch(setRooms(undoRooms[undoRooms.length - 1]));
    }
    if (undoPlot?.length > 0) {
      dispatch(setPlot(undoPlot[undoPlot.length - 1]));
    }
    dispatch(popUndoStacks());
    dispatch(setCleanUpDone(true));
  };

  const redo = () => {
    if (redoWalls.length > 0 && redoRooms.length > 0)
      dispatch(
        pushUndoStacks({ rooms: rooms, walls: walls, plot: plot_boundary }),
      );
    if (redoWalls?.length > 0) {
      dispatch(setWalls(redoWalls[redoWalls.length - 1]));
    }
    if (redoRooms?.length > 0) {
      dispatch(setRooms(redoRooms[redoRooms.length - 1]));
    }
    if (redoPlot?.length > 0) {
      dispatch(setPlot(redoPlot[redoPlot.length - 1]));
    }
    dispatch(popRedoStacks());
    dispatch(setCleanUpDone(true));
  };

  const drawPlot = () => {
    if (plot_boundary && plot_boundary.length > 0) {
      return;
    } else if (rooms) {
      let { minFPX, maxFPX, minFPY, maxFPY } = getFloorplanLimits(rooms);
      let minXWall = Number.MAX_VALUE,
        maxXWall = Number.MIN_VALUE,
        minYWall = Number.MAX_VALUE,
        maxYWall = Number.MIN_VALUE;
      floorPlans[index].forEach((apiRoom) => {
        apiRoom?.walls?.forEach((apiWall: any) => {
          minXWall = Math.min(apiWall.x1, apiWall.x2, minXWall);
          minYWall = Math.min(apiWall.y1, apiWall.y2, minYWall);
          maxXWall = Math.max(apiWall.x1, apiWall.x2, maxXWall);
          maxYWall = Math.max(apiWall.y1, apiWall.y2, maxYWall);
        });
      });

      const width = Math.abs(maxXWall - minXWall);
      const height = Math.abs(maxYWall - minYWall);
      plotWidth = scaleCoordinate(graphdata.plot_width, true, width, height);
      plotHeight = scaleCoordinate(graphdata.plot_height, true, width, height);
      let wall1: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: plotWidth ? (maxFPX + minFPX - plotWidth) / 2 : minFPX - 50,
        x2: plotWidth ? (maxFPX + minFPX + plotWidth) / 2 : maxFPX + 50,
        y1: plotHeight ? (maxFPY + minFPY - plotHeight) / 2 : minFPY - 50,
        y2: plotHeight ? (maxFPY + minFPY - plotHeight) / 2 : minFPY - 50,
      };
      let wall2: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: plotWidth ? (maxFPX + minFPX + plotWidth) / 2 : minFPX - 50,
        x2: plotWidth ? (maxFPX + minFPX - plotWidth) / 2 : maxFPX + 50,
        y1: plotHeight ? (maxFPY + minFPY + plotHeight) / 2 : maxFPY + 50,
        y2: plotHeight ? (maxFPY + minFPY + plotHeight) / 2 : maxFPY + 50,
      };
      let wall3: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: plotWidth ? (maxFPX + minFPX - plotWidth) / 2 : minFPX - 50,
        x2: plotWidth ? (maxFPX + minFPX - plotWidth) / 2 : minFPX - 50,
        y1: plotHeight ? (maxFPY + minFPY - plotHeight) / 2 : minFPY - 50,
        y2: plotHeight ? (maxFPY + minFPY + plotHeight) / 2 : maxFPY + 50,
      };
      let wall4: Wall = {
        id: `plot-${uuidv4()}`,
        roomIds: [],
        x1: plotWidth ? (maxFPX + minFPX + plotWidth) / 2 : maxFPX + 50,
        x2: plotWidth ? (maxFPX + minFPX + plotWidth) / 2 : maxFPX + 50,
        y1: plotHeight ? (maxFPY + minFPY - plotHeight) / 2 : minFPY - 50,
        y2: plotHeight ? (maxFPY + minFPY + plotHeight) / 2 : maxFPY + 50,
      };
      let new_plot_boundary: Wall[] = [wall1, wall2, wall3, wall4];
      dispatch(setPlot(new_plot_boundary));
    }
  };

  // All Tools : arrow, box, pen, tag, pot, text
  const [tool, setTool] = useState("arrow");
  return (
    <>
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          left: "40px",
          bottom: "15px",
          right: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{
            display: "flex",
            gap: "8px",
          }}>
            <Tooltip title={tooltipContent} arrow placement="top">
              <div style={{ zIndex: 100, cursor: "pointer", position: "relative" }}>
                <img src={help} height="40px" width="40px" alt="Help icon" />
                <Typography
                  sx={{
                    pr: "15px",
                    color: "#1C4C82",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "24px",
                  }}
                >
                  Help
                </Typography>
              </div>
            </Tooltip>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
                padding: "0px 10px",
                borderRadius: "8px",
                // boxShadow:'2px 2px 2px 1px',
                border: "1px solid #E0E0E0",
              }}
            >
              <Typography
                sx={{
                  pl: "10px",
                  pr: "10px",
                  color: "#333333",
                  textAlign: "left",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                28%
              </Typography>
              <Box
                sx={{
                  margin: "5px",
                }}
              >
                <RightArrowIcon color="#333333" />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "5px",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
            }}
          >
            <Box
              sx={{
                backgroundColor: tool === "arrow" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("arrow");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <UpLeftCursorIcon
                color={tool === "arrow" ? "#ffffff" : "#333333"}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "select" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("select");
                dispatch(clearDrawLine());
                if (!showPlot) drawPlot();
                dispatch(togglePlot());
                dispatch(setCleanUpDone(true));
              }}
            >
              <SelectBoxIcon
                color={tool === "select" ? "#ffffff" : "#333333"}
              />
            </Box>
            <Box
              sx={{
                mr: "15px",
              }}
            >
              <svg
                width="2"
                height="40"
                viewBox="0 0 2 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.839844" width="1" height="40" fill="#E0E0E0" />
              </svg>
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "pen" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("pen");
                dispatch(setDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <PenIcon color={tool === "pen" ? "#ffffff" : "#333333"} />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "tag" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("tag");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <TagIcon color={tool === "tag" ? "#ffffff" : "#333333"} />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "pot" ? "#1C4C82" : "#ffffff",
                mr: "15px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("pot");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <ColorPotIcon color={tool === "pot" ? "#ffffff" : "#333333"} />
            </Box>
            <Box
              sx={{
                backgroundColor: tool === "text" ? "#1C4C82" : "#ffffff",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
              onClick={() => {
                setTool("text");
                dispatch(clearDrawLine());
                dispatch(setCleanUpDone(true));
              }}
            >
              <TextBoxIcon color={tool === "text" ? "#ffffff" : "#333333"} />
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
                padding: "5px",
                borderRadius: "8px",
                border: "1px solid #E0E0E0",
              }}
            >
              <Typography
                sx={{
                  pr: "10px",
                  color: "#333333",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {unit === "feet" ? "Feet" : "Meters"}
              </Typography>
              <button
                onClick={() => dispatch(toggleUnit())}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#1C4C82",
                  color: "#ffffff",
                  borderRadius: "4px",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Toggle Unit
              </button>
            </Box> */}

            <DimensionsToggle />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
              padding: "0px 10px",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
            }}
          >
            <Box
              sx={{
                padding: "10px",
              }}
              onClick={undo}
            >
              <LeftCurvedArrowIcon color="#333333" />
            </Box>
            <Box
              sx={{
                padding: "10px",
              }}
              onClick={redo}
            >
              <RightCurvedArrowIcon color="#333333" />
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}

