import React, { useCallback } from "react";
import help from "./../../../assets/inputGraphEditor/help.svg";
import {
  Box,
  Typography,
  Tooltip,
  Button,
  TextField,
  MobileStepper,
  Paper,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import { ChevronLeft, ChevronRight, MousePointer, Maximize2, Minimize2, FileJson, Grid, Move, PenTool } from 'lucide-react';
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNodesState,
  updatePlotHeight,
  updatePlotWidth,
} from "../../../redux/features/nodes";
import { resetRoomState } from "../../../redux/features/room/room.slice";
import DimensionsToggle from "../../../common-components/DimensionsToggle";
import RefreshIcon from "@mui/icons-material/Refresh";

const steps = [
  {
    title: "Create Canvas Nodes",
    description:
      "Double click on the canvas to create nodes. These nodes represent rooms or spaces in your floor plan. You can create multiple nodes and position them as needed.",
  },
  {
    title: "Connect Your Nodes",
    description:
      "Click and drag from one node to another to create connections. These connections define the relationships and adjacencies between different spaces in your floor plan.",
  },
  {
    title: "Edit Node Properties",
    description:
      "Double click any node to open the Node Editor. Here you can rename nodes, set dimensions (width/length), and delete nodes if needed.",
  },
  {
    title: "Configure Units",
    description:
      "Use the unit toggle to switch between Feet and Meters. All dimensions will automatically update to reflect your chosen unit of measurement.",
  },
  {
    title: "Edit Plot Size",
    description:
      "Plot width and length are optional. If provided, all generated layouts will adhere to the given size limits, ensuring the floorplans fit within your specified dimensions.",
  },
  {
    title: "Edit Layouts",
    description:
      "After generating floorplans, select a floorplan from the list and click on Edit Layout. This will take you to the Output Editor, where you can customize walls, doors, and other properties as needed.",
  },
  {
    title: "Generate & Export",
    description:
      "Once your nodes are set up, select a floor plan from the sidebar and click Generate Floorplans. You can export your work as JSON for later use or DXF for CAD software.",
  },
];

interface BottomNavProps {
  isSideBarCollapsed?: boolean;
}
export default function BottomNav({
  isSideBarCollapsed = true,
}: BottomNavProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const tooltipContent = (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "white",
        }}
      >
        {/* <Typography sx={{ mr: '2rem' }}>{steps[activeStep].icon}</Typography> */}
        <Typography>{steps[activeStep].title}</Typography>
      </Paper>
      <Box
        sx={{
          height: 255,
          maxWidth: 400,
          width: "100%",
          p: 2,
          fontSize: ".9rem",
        }}
      >
        {steps[activeStep].description}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );

  const dispatch = useDispatch();
  const plotWidth = useSelector((state: any) => state.nodesState.plotWidth);
  const plotHeight = useSelector((state: any) => state.nodesState.plotHeight);
  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePlotWidth(Number(event.target.value)));
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updatePlotHeight(Number(event.target.value)));
  };
  const handleReload = useCallback(() => {
    console.log("refresh triggered");
    dispatch(resetNodesState());
    dispatch(resetRoomState());
  }, [dispatch]);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1000,
        left: "15px",
        bottom: "15px",
        width: "70%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Tooltip title={tooltipContent} arrow placement="top">
          <div style={{ zIndex: 100, cursor: "pointer", position: "relative" }}>
            <img src={help} height="40px" width="40px" alt="Help icon" />
            <Typography
              sx={{
                pr: "15px",
                color: "#1C4C82",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Help
            </Typography>
          </div>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingRight: "2rem",

            position: "fixed",
            right: isSideBarCollapsed ? "-10px" : "calc(30% + 10px)", // Adjust this value based on your layout
            bottom: "15px",
            transform: isSideBarCollapsed ? "translateX(0)" : "translateX(0)",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <DimensionsToggle />
          <Button
            variant="outlined"
            sx={{
              borderColor: "#ED4337",
              color: "#ED4337",
              minWidth: isSideBarCollapsed ? "48px" : "200px", // Initial width when expanded
              width: isSideBarCollapsed ? "48px" : "auto",
              height: "48px",
              padding: isSideBarCollapsed ? 0 : "12px 20px",
              borderRadius: isSideBarCollapsed ? "50%" : "8px",
              backgroundColor: "#FDEDEC",
              transition: "all 0.25s ease-in-out",
              position: "relative",
              overflow: "hidden",
              whiteSpace: "nowrap",
              "& .MuiSvgIcon-root": {
                fontSize: "24px",
                transition: "all 0.25s ease-in-out",
                opacity: isSideBarCollapsed ? 1 : 0,
                position: "absolute",
                left: "50%",
                transform: isSideBarCollapsed
                  ? "translateX(-50%)"
                  : "translateX(-50%) scale(0)",
              },
              "&:hover": {
                borderColor: "#ED4337",
                backgroundColor: "#F9CACA",
                transform: isSideBarCollapsed ? "scale(1.05)" : "scale(1.02)",
                "& .MuiSvgIcon-root": {
                  transform: isSideBarCollapsed
                    ? "translateX(-50%) rotate(180deg)"
                    : "translateX(-50%) scale(0)",
                },
              },
              "&:active": {
                transform: "scale(0.95)",
              },
              // Text styles
              "& .button-text": {
                transition: "all 0.25s ease-in-out",
                opacity: isSideBarCollapsed ? 0 : 1,
                transform: isSideBarCollapsed
                  ? "translateX(20px)"
                  : "translateX(0)",
              },
            }}
            onClick={handleReload}
          >
            <RefreshIcon />
            <span className="button-text">Reset & Clear All Nodes</span>
          </Button>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              padding: "6px 20px",
            }}
          >
            <Typography
              sx={{
                pr: "5px",
                color: "#111111",
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              Plot Size
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                padding: "6px 12px",
              }}
            >
              {/* <Typography
                sx={{
                  pr: "15px",
                  color: "#333333",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                Width
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "8px",
                  padding: "6px 12px",
                }}
              >
                <TextField
                  label="Width (ft)"
                  variant="outlined"
                  size="small"
                  value={plotWidth}
                  onChange={handleWidthChange}
                  sx={{
                    marginRight: ".25rem",
                    backgroundColor: "#F2F2F2",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                padding: "6px 12px",
              }}
            >
              {/* <Typography
                sx={{
                  pr: "15px",
                  color: "#333333",
                  textAlign: "left",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                Height
              </Typography> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "8px",
                  padding: "6px 12px",
                }}
              >
                <TextField
                  label="Length (ft)"
                  variant="outlined"
                  size="small"
                  value={plotHeight}
                  onChange={handleHeightChange}
                  sx={{
                    backgroundColor: "#F2F2F2",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* </Box> */}
      {/* // </Box> */}
    </div>
  );
}
