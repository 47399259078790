import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import loginBG from "../../assets/loginBG.png";
import axios from "axios";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../redux/features/auth/authSlice";
import { useParams } from "react-router-dom";

interface Params {
  [key: string]: string | undefined; // Index signature
  user_id: string;
  token: string;
}

function ResetPassword() {
  const { user_id, token } = useParams<Params>(); // Capture the dynamic parameters
  const [data, setData] = useState<{
    user_id: string;
    token: string;
  } | null>(null);

  useEffect(() => {
    if (user_id && token) {
      setData({ user_id, token });
    }
  }, [user_id, token]);

  let from = "none";
  const { state } = useLocation();
  if (state) from = state.from;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isVisible, setVisibility] = useState<Boolean>(false);

  const [formValues, setFormValues] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: "",
    confirmPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChangeVisibility = () => {
    setVisibility(!isVisible);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleOnSubmit = () => {
    if (formValues.password !== formValues.confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BASEURL}/users/auth/password/reset/`, {
        password: formValues.password,
        user_id: user_id,
        token: token,
      })
      .then((resp) => {
        navigate("/dashboard");
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message || "Something went wrong",
        );
        setFormValues({ password: "", confirmPassword: "" });
      });
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const isFormValid =
    formValues.password &&
    formValues.confirmPassword &&
    formValues.password === formValues.confirmPassword;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
          background: `url(${loginBG}) , lightgray 50% / cover no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            display: "inline-flex",
            padding: "44px 44px 49.5px 44px",
            flexDirection: "column",
            alignItems: "center",
            gap: "33px",
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "0px 4.4px 11px 0px rgba(138, 142, 148, 0.05)",
          }}
        >
          <Box sx={{ height: "91.204px", width: "105.313px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="110"
              viewBox="0 0 96 110"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.0009 2L93.6018 28.3283V80.9839L48.0009 107.313L2.39819 80.9839V28.3283L48.0009 2Z"
                stroke="#1C4C82"
                strokeWidth="3.59941"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.5623 69.9565L65.9584 96.2337"
                stroke="#1C4C82"
                strokeWidth="3.78886"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M74.6055 69.9565L29.2772 96.2336"
                stroke="#1C4C82"
                strokeWidth="3.81813"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M47.9983 2V54.6563M47.9983 54.6563L2.94873 80.8096M47.9983 54.6563L93.6012 80.6264"
                stroke="#1C4C82"
                strokeWidth="3.59941"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.2759 18.6323L21.3536 50.033L21.3345 69.6664"
                stroke="#1C4C82"
                strokeWidth="3.78886"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M48.2749 3.3501L74.6075 69.9564"
                stroke="#1C4C82"
                strokeWidth="3.78886"
                strokeMiterlimit="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5.5px",
            }}
          >
            <Typography
              sx={{
                display: "inline",
                color: "#282828",
                fontFeatureSettings: `'clig' off, 'liga' off`,
                fontFamily: "Poppins",
                fontSize: "26.4px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "39.6px" /* 150% */,
              }}
            >
              Jump Right Back into
              <Typography
                component="span"
                sx={{
                  color: "#1C4C82",
                  fontFeatureSettings: `'clig' off, 'liga' off`,
                  fontFamily: "Poppins",
                  fontSize: "26.4px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "39.6px",
                }}
              >
                {" "}
                GPlan
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: "#949CA9",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px" /* 150% */,
              }}
            >
              Reset Your Password
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "22px",
            }}
          >
            <TextField
              fullWidth
              required
              size="small"
              type={isVisible ? "text" : "password"}
              label="Enter your password"
              name="password"
              value={formValues.password}
              onChange={handleTextFieldChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isVisible ? (
                      <VisibilityOffOutlinedIcon
                        onClick={handleChangeVisibility}
                        sx={{
                          "&:hover": { cursor: "pointer" },
                        }}
                      />
                    ) : (
                      <RemoveRedEyeOutlinedIcon
                        onClick={handleChangeVisibility}
                        sx={{
                          "&:hover": { cursor: "pointer" },
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              required
              size="small"
              type={isVisible ? "text" : "password"}
              label="Confirm your password"
              name="confirmPassword"
              value={formValues.confirmPassword}
              onChange={handleTextFieldChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isVisible ? (
                      <VisibilityOffOutlinedIcon
                        onClick={handleChangeVisibility}
                        sx={{
                          "&:hover": { cursor: "pointer" },
                        }}
                      />
                    ) : (
                      <RemoveRedEyeOutlinedIcon
                        onClick={handleChangeVisibility}
                        sx={{
                          "&:hover": { cursor: "pointer" },
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {errorMessage && (
              <Typography
                sx={{
                  color: "red",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                }}
              >
                {errorMessage}
              </Typography>
            )}
            <Button
              fullWidth
              size="large"
              onClick={handleOnSubmit}
              variant="contained"
              disabled={!isFormValid}
              sx={{
                backgroundColor: isFormValid ? "#1C4C82" : "#CECECE",
              }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "17.6px",
                  fontWeight: "500",
                }}
              >
                Reset Password
              </Typography>
            </Button>
          </Box>
          <Typography
            sx={{
              color: "#282828",
              fontFamily: "Poppins",
              fontSize: "16px",
            }}
          >
            {" "}
            New to GPlan?{" "}
            <Button onClick={goToSignup}>
              <Typography
                component="span"
                sx={{
                  color: "#009EF7",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                }}
              >
                Get Started
              </Typography>
            </Button>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default ResetPassword;
