import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { NameContext } from "./DashBoard";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  updateAddLinks,
  updateAddNodes,
  updateLinks,
  updateNodeLabel,
  resetNodesState,
} from "../../../redux/features/nodes";
import {
  updateNodeEditorClickedNode,
  updateNodeEditorPosition,
  updateShowComponent,
} from "../../../redux/features/nodeEditor/nodeEditor.slice";
import { doLinesIntersect } from "../../../utils/doLinesIntersect";
import { showToast } from "../../../redux/features/toast/toast.slice";
import TopNav from "./TopNav";
import BottomNav from "./BottomNav";
import NodeEditor from "./NodeEditor";
import { setFromDocuments } from "../../../redux/features/graph";
import Sidebar from "./Sidebar";

const CustomDiv = styled.div`
  .svgDualGraph {
    cursor: crosshair;
    display: block;
    background: #f2f7fc;
    width: 100vw;
    height: 100vh;
  }
  .edge {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
    cursor: default;
  }
  .edge-hover {
    stroke: transparent;
    stroke-width: 20px;
    cursor: pointer;
  }
  .drag-line {
    stroke: #1c4c82;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
    cursor: default;
  }
  .vertex {
    cursor: pointer;
  }
  .vertex:hover {
    stroke: #333;
    opacity: 0.8;
  }
  .label {
    text-anchor: middle;
    pointer-events: none;
    font-size: 16px;
    fill: #ffffff;
    stroke: #ffffff;
  }
`;

declare global {
  interface Window {
    isHoveringNodeOrEditor: boolean;
  }
}

const GRID_SIZE = 20;
const Graph = (props: any) => {
  const RADIUS = 65;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const contentGroupRef = useRef<SVGGElement | null>(null);
  const currentZoom = useRef(d3.zoomIdentity);
  const dispatch = useDispatch<AppDispatch>();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [editorKey, setEditorKey] = useState(0);
  const showComponent = useSelector(
    (store: RootState) => store.nodeEditorState.showComponent,
  );

  const nodes = useSelector((store: RootState) => store?.nodesState?.nodes);
  const links = useSelector((store: RootState) => store.nodesState?.links);
  const fromDocuments = useSelector(
    (store: RootState) => store.graph?.fromDocuments,
  );

  const [hoveredEdge, setHoveredEdge] = useState<any>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [dragging, setDragging] = useState(false);
  const [dragStartNode, setDragStartNode] = useState<{
    id: number;
    x: number;
    y: number;
  } | null>(null);

  const closeNodeEditor = () => {
    dispatch(updateShowComponent(false));
  };

  let isMouseOverEditor = false; // Tracks if the mouse is over the NodeEditor
  let hideTimeout: ReturnType<typeof setTimeout>; // Timeout to manage hide delay
  const [isHovered, setIsHovered] = useState(false);

  function isNodePossible(
    x: number,
    y: number,
    nodes: {
      id: number;
      x: number;
      y: number;
      label: string;
      color: string;
    }[],
  ) {
    if (nodes.length > 13) {
      return false;
    }
    for (let i = 0; i < nodes.length; i++) {
      let calc = (nodes[i].x - x) ** 2 + (nodes[i].y - y) ** 2;
      if (calc < (RADIUS * 2) ** 2) return false;
    }
    return true;
  }

  const snapToGrid = (x: number, y: number) => {
    const transformedPoint = currentZoom.current.invert([x, y]);
    return [
      Math.round(transformedPoint[0] / GRID_SIZE) * GRID_SIZE,
      Math.round(transformedPoint[1] / GRID_SIZE) * GRID_SIZE,
    ];
  };

  const zoomBehavior = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.5, 2])
    .on("zoom", (event) => {
      currentZoom.current = event.transform;
      d3.select(svgRef.current)
        .select(".main-group")
        .attr("transform", event.transform.toString());
      drawGrid(
        d3.select(svgRef.current).select(".grid-group"),
        event.transform,
      );
    });

  useEffect(() => {
    if (!svgRef.current) return;
    d3.select(svgRef.current).selectAll("*").remove();

    const svgElement: SVGSVGElement = svgRef.current;
    const svg = d3.select(svgElement);

    const mainGroup = svg.append("g").attr("class", "main-group");

    d3.select(svgRef.current)
      .select(".main-group")
      .attr("transform", currentZoom.current.toString());
    // Grid group
    const gridGroup = mainGroup.append("g").attr("class", "grid-group");
    drawGrid(gridGroup, d3.zoomIdentity);

    // Content group
    const contentGroup = mainGroup.append("g").attr("class", "content-group");
    update(contentGroup);
    contentGroupRef.current = contentGroup.node();

    // Define and apply zoom behavior
    svg.call(zoomBehavior as any).on("dblclick.zoom", null);
    svg.call(zoomBehavior).on("dblclick.zoom", null).on("mousedown.zoom", null);
    svg.on("dblclick", (event: MouseEvent) => {
      event.preventDefault();
      const [x, y] = d3.pointer(event);
      const [snappedX, snappedY] = snapToGrid(x, y);
      const [nodeX, nodeY] = d3.pointer(event, svgRef.current);
      const clickedNode = findNode(nodeX, nodeY);
      let check = isNodePossible(snappedX, snappedY, nodes);
      if (check) {
        const id = nodes.length;
        const label = `${nodes.length + 1}`;
        const color = "#1C4C82";

        dispatch(
          updateAddNodes({
            id: id,
            x: snappedX,
            y: snappedY,
            label: label,
            color: color,
            width: { max: 99999, min: 3 },
            height: { max: 99999, min: 3 },
            ratio: { max: 99999, min: 3 },
          }),
        );
      }
      if (clickedNode) {
        nodes.forEach((node, index) => {
          if (node.label?.length === 0) {
            dispatch(
              updateNodeLabel({
                id: index,
                label: `${index + 1}`,
              }),
            );
          }
        });
        dispatch(updateNodeEditorClickedNode(clickedNode.id));
        dispatch(
          updateNodeEditorPosition({
            top: clickedNode?.y,
            left: clickedNode?.x,
          }),
        );
        dispatch(updateShowComponent(true));
      }
    });
    let dragTimeout: any;

    svg.on("mousedown", (event: MouseEvent) => {
      event.preventDefault();
      const [x, y] = d3.pointer(event, svgRef.current);
      const clickedNode = findNode(x, y);
      if (clickedNode) {
        // Delay setting the dragging state
        dragTimeout = setTimeout(() => {
          setDragging(true);
          setDragStartNode(clickedNode);

          if (contentGroupRef.current) {
            drawDragLine(
              clickedNode.x,
              clickedNode.y,
              clickedNode.x,
              clickedNode.y,
              d3.select(contentGroupRef.current),
            );
          }
        }, 200); // 200ms delay, adjust as needed
      }
    });

    svg.on("mousemove", (event: MouseEvent) => {
      event.preventDefault();
      if (dragging && dragStartNode) {
        const [x, y] = d3.pointer(event, svg.node());
        const [snappedX, snappedY] = snapToGrid(x, y);
        if (contentGroupRef.current) {
          drawDragLine(
            dragStartNode.x,
            dragStartNode.y,
            snappedX,
            snappedY,
            d3.select(contentGroupRef.current),
          );
        }
      }
    });

    svg.on("click", (event: MouseEvent) => {
      event.preventDefault();
      const [nodeX, nodeY] = d3.pointer(event, svgRef.current);
      const clickedNode = findNode(nodeX, nodeY);

      if (clickedNode === undefined) {
        dispatch(updateShowComponent(false));
      }
    });

    svg.on("mouseup", (event: MouseEvent) => {
      clearTimeout(dragTimeout);
      if (dragging && dragStartNode) {
        const [x, y] = d3.pointer(event, svg.node());
        const endNode = findNode(x, y);
        let intersects = false;
        if (endNode && dragStartNode.id !== endNode.id) {
          for (let link of links) {
            const sourceNode = nodes.find((node) => node.id === link.source);
            const targetNode = nodes.find((node) => node.id === link.target);

            if (sourceNode && targetNode) {
              if (
                doLinesIntersect(
                  { x: dragStartNode.x, y: dragStartNode.y },
                  { x: endNode.x, y: endNode.y },
                  { x: sourceNode.x, y: sourceNode.y },
                  { x: targetNode.x, y: targetNode.y },
                )
              ) {
                intersects = true;
                break;
              }
            }
          }
        }

        console.log("intersects", intersects);
        if (/* !intersects &&  */ endNode && dragStartNode.id !== endNode.id) {
          console.log("reached here in mouse up!");
          dispatch(
            updateAddLinks({
              source: dragStartNode.id,
              target: endNode.id,
              color: "black", // Store as "black"
            }),
          );
          update(d3.select(contentGroupRef.current!)); // Immediately update after adding new link
        } /* else if (intersects) {
          console.log("Intersecting links are not allowed.");
          dispatch(
            showToast({
              message: "Intersecting edges are not allowed.",
              type: "error",
            }),
          );
        } */

        removeDragLine(d3.select(contentGroupRef.current!));
        setDragging(false);
        setDragStartNode(null);
      }
    });
  }, [nodes, links, dragging, dragStartNode]);

  useEffect(() => {
    console.log("PROPS FROM NODESSS OUTSIDEx", props.nodesFromDoc);
    if (fromDocuments && props.nodesFromDoc && props.edgesFromDoc) {
      dispatch(resetNodesState());
      console.log("PROPS FROM NODESSS");
      const width = visualViewport?.width;
      const height = visualViewport?.height;
      if (width && height) {
        props.nodesFromDoc.forEach((node: any) => {
          const [snappedX, snappedY] = snapToGrid(
            node.x * width,
            node.y * height,
          );
          dispatch(
            updateAddNodes({
              ...node,
              x: snappedX,
              y: snappedY,
            }),
          );
        });
        dispatch(setFromDocuments(false));
      }
      dispatch(updateLinks(props.edgesFromDoc));
    }
  }, []);

  function findNode(x: number, y: number) {
    const [invertedX, invertedY] = currentZoom.current.invert([x, y]);
    return nodes.find((node) => {
      const dx = invertedX - node.x;
      const dy = invertedY - node.y;
      return Math.sqrt(dx * dx + dy * dy) < RADIUS;
    });
  }

  function drawDragLine(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) {
    removeDragLine(contentGroup);
    contentGroup
      .append("line")
      .attr("class", "drag-line")
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("stroke", "#888")
      .attr("stroke-width", 3)
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round");
  }

  function removeDragLine(
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) {
    contentGroup.select(".drag-line").remove();
  }

  const drawGrid = (
    gridGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
    zoomTransform: d3.ZoomTransform,
  ) => {
    if (!svgRef.current) return;
    const width = +svgRef.current.clientWidth;
    const height = +svgRef.current.clientHeight;
    const GRID_SIZE = 20;
    // Clear the previous grid
    gridGroup.selectAll("*").remove();

    // Calculate the visible area in the original coordinate system
    const visibleWidth = width / zoomTransform.k;
    const visibleHeight = height / zoomTransform.k;

    // Calculate the top-left corner of the visible area
    const startX = -zoomTransform.x / zoomTransform.k;
    const startY = -zoomTransform.y / zoomTransform.k;

    // Draw the grid dots
    for (
      let x = Math.floor(startX / GRID_SIZE) * GRID_SIZE;
      x <= startX + visibleWidth;
      x += GRID_SIZE
    ) {
      for (
        let y = Math.floor(startY / GRID_SIZE) * GRID_SIZE;
        y <= startY + visibleHeight;
        y += GRID_SIZE
      ) {
        gridGroup
          .append("circle")
          .attr("cx", x)
          .attr("cy", y)
          .attr("r", 1)
          .attr("fill", "#ccc");
      }
    }
  };

  const deleteEdge = (sourceId: any, targetId: any) => {
    const updatedLinks = links.filter(
      (link) => link.source !== sourceId || link.target !== targetId,
    );

    dispatch(updateLinks(updatedLinks));
    if (contentGroupRef.current) {
      update(d3.select(contentGroupRef.current));
    }
  };

  // Function to map color names to hex codes
  const getEdgeColor = (colorName: string) => {
    if (colorName === "black") return "#1C4C82";
    if (colorName === "red") return "#ED4337";
    return "#1C4C82"; // Default to black if undefined
  };

  function update(
    contentGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  ) {
    // Update edges using enter-update-exit pattern
    const edgeGroups = contentGroup
      .selectAll(".edge-group")
      .data(links, (d: any) => `${d.source}-${d.target}`);

    // Remove exiting edges
    edgeGroups.exit().remove();

    // Update existing edges
    edgeGroups
      .select(".edge")
      .attr("x1", (d) => nodes.find((node) => node.id === d.source)?.x || 0)
      .attr("y1", (d) => nodes.find((node) => node.id === d.source)?.y || 0)
      .attr("x2", (d) => nodes.find((node) => node.id === d.target)?.x || 0)
      .attr("y2", (d) => nodes.find((node) => node.id === d.target)?.y || 0)
      .attr("stroke", (d) => getEdgeColor(d.color))
      .attr("stroke-width", 3)
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round");

    edgeGroups
      .select(".edge-hover")
      .attr("x1", (d) => nodes.find((node) => node.id === d.source)?.x || 0)
      .attr("y1", (d) => nodes.find((node) => node.id === d.source)?.y || 0)
      .attr("x2", (d) => nodes.find((node) => node.id === d.target)?.x || 0)
      .attr("y2", (d) => nodes.find((node) => node.id === d.target)?.y || 0);

    // Add new edges
    const edgeGroupsEnter = edgeGroups
      .enter()
      .insert("g", ":first-child") // Insert before existing nodes and labels
      .attr("class", "edge-group");

    edgeGroupsEnter
      .append("line")
      .attr("class", "edge")
      .attr("x1", (d) => nodes.find((node) => node.id === d.source)?.x || 0)
      .attr("y1", (d) => nodes.find((node) => node.id === d.source)?.y || 0)
      .attr("x2", (d) => nodes.find((node) => node.id === d.target)?.x || 0)
      .attr("y2", (d) => nodes.find((node) => node.id === d.target)?.y || 0)
      .attr("stroke", (d) => getEdgeColor(d.color))
      .attr("stroke-width", 3)
      .attr("stroke-linecap", "round")
      .attr("stroke-linejoin", "round");

    edgeGroupsEnter
      .append("line")
      .attr("class", "edge-hover")
      .attr("x1", (d) => nodes.find((node) => node.id === d.source)?.x || 0)
      .attr("y1", (d) => nodes.find((node) => node.id === d.source)?.y || 0)
      .attr("x2", (d) => nodes.find((node) => node.id === d.target)?.x || 0)
      .attr("y2", (d) => nodes.find((node) => node.id === d.target)?.y || 0)
      .attr("stroke", "transparent")
      .attr("stroke-width", 20)
      .attr("cursor", "pointer")
      .on("mouseover", function (event, d) {
        event.stopPropagation();

        // Calculate midpoint of the edge
        const sourceNode = nodes.find((node) => node.id === d.source);
        const targetNode = nodes.find((node) => node.id === d.target);

        if (sourceNode && targetNode && svgRef.current) {
          const midX = (sourceNode.x + targetNode.x) / 2;
          const midY = (sourceNode.y + targetNode.y) / 2;

          // Create an SVG point in the coordinate system of the SVG element
          const svgPoint = svgRef.current.createSVGPoint();
          svgPoint.x = midX;
          svgPoint.y = midY;

          // Get the transformation matrix for the SVG element
          const ctm = svgRef.current.getScreenCTM();
          if (ctm) {
            // Transform the point to screen coordinates
            const screenPoint = svgPoint.matrixTransform(ctm);

            // Set the position of the dropdown
            setHoveredEdge(d);
            setDropdownPosition({ x: screenPoint.x, y: screenPoint.y });
          }
        }
      })
      .on("mouseout", function (event, d) {
        event.stopPropagation();
        const related = event.relatedTarget as Element;
        if (
          dropdownRef.current &&
          (dropdownRef.current === related ||
            dropdownRef.current.contains(related))
        ) {
          // Mouse moved to dropdown, do not hide
          return;
        } else {
          // Mouse left to somewhere else, hide the dropdown
          setHoveredEdge(null);
        }
      })
      .on("click", (event, d) => {
        event.stopPropagation();
        deleteEdge(d.source, d.target);
      });

    // Nodes and labels remain the same
    const nodeSelection = contentGroup
      .selectAll<SVGCircleElement, any>(".vertex")
      .data(nodes, (d: any) => d.id);

    nodeSelection
      .enter()
      .append("circle")
      .attr("class", "vertex")
      .attr("r", RADIUS)
      .merge(nodeSelection)
      .attr("cx", (d) => d.x)
      .attr("cy", (d) => d.y)
      .style("fill", (d) => d.color)
      .on("mouseover", (event, d) => {
        // Keep the NodeEditor visible and update state
        dispatch(updateNodeEditorClickedNode(d.id));
        dispatch(
          updateNodeEditorPosition({
            top: d.y,
            left: d.x,
          }),
        );
        dispatch(updateShowComponent(true));
        setTimeout(() => {
          window.isHoveringNodeOrEditor = true; // Set hover flag
        }, 0);
      })
      .on("mouseout", (event) => {
        const relatedTarget = event.relatedTarget as Element;

        // Prevent hiding if moving to NodeEditor
        if (
          relatedTarget &&
          (relatedTarget.closest(".node-editor") ||
            relatedTarget.closest(".vertex"))
        ) {
          return;
        }
        window.isHoveringNodeOrEditor = false;
        setTimeout(() => {
          if (!window.isHoveringNodeOrEditor) {
            dispatch(updateShowComponent(false)); // Hide NodeEditor
          }
        }, 100); // Delay hiding to allow smooth transition
      });

    nodeSelection.exit().remove();

    const labelSelection = contentGroup
      .selectAll<SVGTextElement, any>(".label")
      .data(nodes, (d: any) => d.id);
    labelSelection
      .enter()
      .append("text")
      .attr("class", "label")
      .merge(labelSelection)
      .attr("x", (d) => d.x)
      .attr("y", (d) => d.y + 5)
      .attr("text-anchor", "middle")
      .style("font-size", (d) => {
        // Adjust font size based on label length
        if (d.label?.length > 16) {
          return "10px";
        } else {
          return "16px";
        }
      })
      .text((d) => {
        if (d.label.startsWith(`${d.id + 1}`)) {
          return d.label;
        } else {
          return d.id + 1 + "-" + d.label;
        }
      });

    labelSelection.exit().remove();
  }

  const handleChangeEdgeColor = (event: any, edge: any) => {
    const selectedColor = event.target.value;

    // Update or remove the edge in the Redux store
    const updatedLinks = links.filter((link) => {
      if (link.source === edge.source && link.target === edge.target) {
        return selectedColor !== "delete"; // Remove the link if "delete" is selected
      }
      return true; // Keep other links unchanged
    });

    // If not deleting, update the color
    const finalLinks =
      selectedColor !== "delete"
        ? updatedLinks.map((link) => {
            if (link.source === edge.source && link.target === edge.target) {
              return { ...link, color: selectedColor };
            }
            return link;
          })
        : updatedLinks;

    dispatch(updateLinks(finalLinks));
  };

  useEffect(() => {
    nodes.forEach((node, index) => {
      if (node.label?.length === 0) {
        dispatch(
          updateNodeLabel({
            id: index,
            label: `${index + 1}`,
          }),
        );
      }
    });
  }, [showComponent]);

  return (
    <div style={{ position: "relative" }}>
      {showComponent === true && (
        <div
          style={{
            zIndex: 1001,
            position: "fixed",
          }}
          onMouseEnter={() => {
            setIsHovered(true); // Keep NodeEditor visible
          }}
          onMouseLeave={() => {
            setIsHovered(false); // Allow NodeEditor to hide
            setTimeout(() => {
              if (!isHovered) {
                dispatch(updateShowComponent(false)); // Hide only if not hovered
              }
            }, 200); // Match delay with node hover
          }}
        >
          <NodeEditor
            key={editorKey}
            // onClose={() => {
            //   closeNodeEditor();
            //   setEditorKey((prevKey) => prevKey + 1); // Increment the key
            // }}
          />
        </div>
      )}

      <CustomDiv style={{ zIndex: 100 }}>
        <svg
          ref={svgRef}
          className="svgDualGraph"
          width={visualViewport?.width}
          height={visualViewport?.height}
        />
      </CustomDiv>
      <TopNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <BottomNav isSideBarCollapsed={!sidebarOpen} />
      <div
        style={
          sidebarOpen
            ? {
                position: "fixed",
                zIndex: 1000,
                right: "8px",
                top: "2px",
                transition: "all .25s linear",
              }
            : {
                position: "fixed",
                zIndex: 1000,
                right: "-30%",
                top: "2px",
                transition: "all .25s linear",
              }
        }
      >
        <Sidebar />
      </div>
      {hoveredEdge && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            left: dropdownPosition.x,
            top: dropdownPosition.y,
            zIndex: 1000,
            backgroundColor: "white",
            border: "2px solid #a3b7c1", // Light blue border color
            padding: "5px",
            borderRadius: "12px", // Rounded border to match the reference
            transform: "translate(-50%, -50%)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add shadow for depth
            display: "flex",
            alignItems: "center",
          }}
          onMouseOver={(event) => {
            // Keep the dropdown visible
          }}
          onMouseOut={(event) => {
            const related = event.relatedTarget as Element;
            if (
              related &&
              (related.closest(".edge-hover") || related.closest(".edge"))
            ) {
              return;
            } else {
              setHoveredEdge(null);
            }
          }}
        >
          <select
            value={hoveredEdge.color}
            onChange={(e) => handleChangeEdgeColor(e, hoveredEdge)}
            style={{
              border: "none",
              outline: "none",
              color: "#204963", // Dark blue color for text
              padding: "8px",
              borderRadius: "8px",
              backgroundColor: "#e3eff5", // Light blue background color
              fontWeight: "bold",
              appearance: "none", // Remove default arrow
              marginRight: "5px",
            }}
          >
            <option value="black">Adjancey</option>
            <option value="red">Non-Adjancey</option>
            <option value="delete">Delete</option>
          </select>
          <span
            style={{
              display: "inline-block",
              borderTop: "5px solid #204963", // Dark blue arrow color
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              marginLeft: "-15px", // Adjust to place the arrow over dropdown
            }}
          ></span>
        </div>
      )}
    </div>
  );
};

export default Graph;
