import {
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import React, { useState } from "react";
import GplanIconWhite from "../../../common-components/icons/GplanIconWhite";
import MenuIcon from "@mui/icons-material/Menu"; // For the hamburger menu icon
import { Link, useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation

export default function Navbar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate(); // Hook for navigation

  // Explicitly typing the event parameter
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  // Function to handle logout
  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage variables
    navigate("/"); // Redirect to the home page
  };

  return (
    <>
      <div className="homepage-navbar-outerdiv">
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Left corner with dropdown */}
          <Box>
            <IconButton
              edge="start"
              color="inherit"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
              style={{ margin: "0 0 0 3em" }}
            >
              <MenuIcon style={{ color: "#F2F2F2" }} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to="/login">
                Login
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/home">
                Home
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/plans">
                Plans
              </MenuItem>
            </Menu>
          </Box>

          {/* Center with logo and GPLAN text */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "30px",
              marginLeft: "72px",
            }}
          >
            <GplanIconWhite />
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Barlow semi Condensed",
                fontSize: "20px",
                fontStyle: "normal",
                color: "#F2F2F2",
                fontWeight: 500,
                lineHeight: "normal",
                padding: "8px",
              }}
            >
              GPLAN
            </Typography>
          </Box>

          {/* Right corner with a user icon */}
          <Box
            sx={{
              marginRight: "72px",
            }}
          >
            <IconButton
              onClick={handleUserMenuClick}
              sx={{ color: "#F2F2F2" }}
              aria-controls="user-menu"
              aria-haspopup="true"
            >
              <Avatar sx={{ backgroundColor: "#F2F2F2", color: "#000" }}>
                👤
              </Avatar>
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={userAnchorEl}
              keepMounted
              open={Boolean(userAnchorEl)}
              onClose={handleUserMenuClose}
            >
              <MenuItem onClick={handleUserMenuClose}>
                <Typography variant="body2">
                  <strong>Username:</strong> {localStorage.getItem("name")}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleUserMenuClose}>
                <Typography variant="body2">
                  <strong>Current Plan:</strong> {localStorage.getItem("plan")}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleUserMenuClose}>
                <Typography variant="body2">
                  <strong>Start Date:</strong>{" "}
                  {localStorage.getItem("start_date")?.split("T")[0]}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleUserMenuClose}>
                <Typography variant="body2">
                  <strong>End Date:</strong>{" "}
                  {localStorage.getItem("end_date")?.split("T")[0]}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography variant="body2" color="error">
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </div>
    </>
  );
}
